import { Location, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  DialogService,
  FeatureFlagService,
  JRCookieService,
  ProfileService,
  TitleService,
} from '@jr/shared/services';
import {
  LoginDialogComponent,
  ProfileTypes,
  UiModule,
  UserStatus,
} from '@jr/ui';
import { environment } from '../../../../environments/environment';
import {
  GITHUB,
  IDM_SERVICE_PATH,
  LINKEDIN,
  FACEBOOK,
  GOOGLE,
  GENERAL_TERMS_DEV_URL,
  GENERAL_TERMS_PROD_URL,
} from '../../../shared/constants/url.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AuthState,
  BUSINESS_USER_INVITE_ID_COOKIE,
  Features,
  IIdentity,
  IOrganization,
  PROFILE_TYPE_COOKIE,
  Profile,
} from '@jr/types';

type ModalData = {
  isSignup: boolean;
  state: AuthState;
};

@Component({
  standalone: true,
  imports: [UiModule],
  template: `
    <jrui-login-dialog
      *ngIf="userLoginV2"
      [state]="state"
      [isSignup]="isSignup"
      [email]="email"
      [inviteId]="inviteId"
      [githubUrl]="githubUrl"
      [linkedInUrl]="linkedInUrl"
      [facebookUrl]="facebookUrl"
      [googleUrl]="googleUrl"
      [downloadUrl]="downloadUrl"
      (signup)="onSignup()"
      (login)="onLogin($event)"
      (closeDialog)="onClose($event)"
      (magicLink)="magicLinkAutomationLogin($event)"
      (updateUser)="updateUser($event)"
      (createOrg)="createOrg($event)"
      (setProfileStatus)="setProfileStatus($event)"
      (setProfileCookie)="setProfileCookie($event)"
      (redirect)="redirect()"
    ></jrui-login-dialog>
    <jrui-login-landing-page
      *ngIf="!userLoginV2"
      [email]="email"
      [githubUrl]="githubUrl"
      [linkedInUrl]="linkedInUrl"
      [facebookUrl]="facebookUrl"
      [googleUrl]="googleUrl"
      (signin)="onSignin($event)"
    ></jrui-login-landing-page>
  `,
})
export default class LoginComponent implements OnInit {
  email = '';
  inviteId = '';
  githubUrl = `${environment.API_ROOT}${IDM_SERVICE_PATH}${GITHUB}`;
  linkedInUrl = `${environment.API_ROOT}${IDM_SERVICE_PATH}${LINKEDIN}`;
  facebookUrl = `${environment.API_ROOT}${IDM_SERVICE_PATH}${FACEBOOK}`;
  googleUrl = `${environment.API_ROOT}${IDM_SERVICE_PATH}${GOOGLE}`;
  downloadUrl = '';
  isSignup = false;
  state = AuthState.Login;
  userLoginV2 = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private dialogService: DialogService,
    private featureService: FeatureFlagService,
    private profileService: ProfileService,
    private cookieService: JRCookieService,
    private titleService: TitleService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Sign In');
    this.checkFeatureFlags();
    this.checkEnv();
    this.getEmail();
  }

  checkFeatureFlags() {
    this.featureService
      .getFeatureFlag(Features.userLoginV2)
      .subscribe((flag) => {
        this.userLoginV2 = flag.enabled;

        if (this.userLoginV2) {
          this.checkData();
          this.onResize();
          this.getInviteId();
        } else {
          this.githubUrl += '/login';
          this.linkedInUrl += '/login';
          this.facebookUrl += '/login';
          this.googleUrl += '/login';
        }
      });
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.downloadUrl = GENERAL_TERMS_DEV_URL;
      } else {
        this.downloadUrl = GENERAL_TERMS_PROD_URL;
      }
    }
  }

  checkData() {
    if (this.data) {
      this.isSignup = this.data.isSignup;
      this.state = this.data.state;
    }
  }

  getEmail() {
    if (this.location.getState()) {
      const { email } = this.location.getState() as { email: string };
      this.email = email;
    }
  }

  onSignin(email: string) {
    this.authService.sendMagicLink(email, 'signin').subscribe({
      next: () => {
        this.router.navigate(['magic-link-sent'], {
          relativeTo: this.route,
          state: { email: email },
        });
      },
      error: () => {
        const errorMsg = `We did not find an account for the email address
         ${email}. Please try again or <a href="/signup"><strong class="jimmyPurple">sign up for an account</strong></a>.`;

        this.dialogService.showAlert(`Account Not Found`, errorMsg);
      },
    });
  }

  getInviteId() {
    // Continue signup process of invited users
    this.inviteId = this.route.snapshot.queryParams['inviteId'];
    if (this.inviteId && this.inviteId !== 'false') {
      this.cookieService.createOrUpdate(
        BUSINESS_USER_INVITE_ID_COOKIE,
        this.inviteId
      );
    }

    this.inviteId = this.cookieService.getCookie(
      BUSINESS_USER_INVITE_ID_COOKIE
    );
  }

  onSignup() {
    this.isSignup = !this.isSignup;
  }

  onLogin(email: string) {
    if (this.isSignup) {
      this.authService.sendMagicLink(email, 'signup', this.inviteId).subscribe({
        error: (err) => {
          this.dialogService.showAlert('Error!', err.error.msg);
        },
      });
    } else {
      this.authService.sendMagicLink(email, 'signin').subscribe({
        error: () => {
          const errorMsg = `We did not find an account for the email address
           ${email}. Please try again or <a href="/?inviteId=false"><strong class="jimmyPurple">sign up for an account</strong></a>.`;

          this.dialogService.dialog.closeAll();
          this.dialogService.showAlert(`Account Not Found`, errorMsg);
        },
      });
    }
  }

  onClose(logout = false) {
    if (logout) {
      this.authService.logout();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  magicLinkAutomationLogin(email: string) {
    this.authService.getEmailAccount(email).subscribe((emailAccount) => {
      this.onClose();
      this.router.navigate(
        [`${this.isSignup ? 'signup' : 'signin'}/magic-link`],
        {
          queryParams: { token: emailAccount.accessToken },
        }
      );
    });
  }

  createOrg(org: Partial<IOrganization>) {
    this.authService.createOrgProfile(org).subscribe({
      next: () => {},
      error: (err) => {
        console.log(err);
      },
    });
  }

  setProfileStatus(data: { status: UserStatus; profileType?: ProfileTypes }) {
    this.authService.updateUserSignupStatus(data).subscribe({
      error: (err) => {
        console.log(err);
      },
    });
  }

  updateUser(user: Partial<IIdentity>) {
    this.profileService.updateMyProfile(user).subscribe({
      next: (user) => {
        console.log('user:', user);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  setProfileCookie(profile: Profile) {
    this.cookieService.createOrUpdate(PROFILE_TYPE_COOKIE, profile);
  }

  redirect() {
    this.authService.getLoggedInUser().subscribe({
      next: (user) => {
        if (isPlatformBrowser(this.platformId)) {
          const persons_pg = localStorage.getItem('persons_pg');
          if (this.isSignup) {
            this.router.navigate(['secure/dashboard']);
          } else {
            this.router.navigate([
              user?.orgId || !persons_pg ? 'secure/dashboard' : persons_pg,
            ]);
          }
          this.dialogRef.close();
          this.cookieService.clearAllCookies();
        }
      },
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (this.userLoginV2 && this.dialogRef) {
      if (this.isMobile()) {
        this.dialogRef.removePanelClass('integration-container');
        this.dialogRef.updateSize('100vw', '100vh');
      } else {
        const signupWidth = ![
          AuthState.Login,
          AuthState.Signup,
          AuthState.LinkSent,
        ].includes(this.state);
        this.dialogRef.addPanelClass('integration-container');
        this.dialogRef.updateSize(signupWidth ? '592px' : '450px', 'auto');
      }
    }
  }

  isMobile(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return window.innerWidth < 1024;
    }
    return false;
  }
}
