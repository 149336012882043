import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, share } from 'rxjs';

import {
  CandidateReferral,
  IApplications,
  MergedDashboardBonus,
  CandidateCombinedExperience,
} from '@jr/types';

import { environment } from '../../../environments/environment';
import { REFERRER_PATH } from '../constants/url.constants';
import { ReferralProp } from '../../state';
import { ReferralLinkService } from './referralLink.service';

type Filter = {
  type?: [string];
  status?: [string];
  company?: [string];
};

@Injectable({
  providedIn: 'root',
})
export class CandidateService {
  constructor(
    private http: HttpClient,
    private referralLinkService: ReferralLinkService
  ) {}

  getReferred() {
    return this.http.get<CandidateReferral[]>(
      `${environment.API_ROOT}${REFERRER_PATH}/referred`
    );
  }

  getMergedDashboardStatistics() {
    return this.http.get<MergedDashboardBonus>(
      `${environment.API_ROOT}${REFERRER_PATH}/merged-dashboard-statistics`
    );
  }

  getCandidateCombinedExperience() {
    return this.http.get<CandidateCombinedExperience>(
      `${environment.API_ROOT}${REFERRER_PATH}/combined-experience`
    );
  }

  getApplications(
    startAt?: {
      tskey: string;
      pkey: string;
      tpkey: string;
      skey: string;
    },
    filter?: Filter
  ) {
    // Base path
    const path = 'applications';

    // Query params array
    const queryParams: string[] = [];

    // Add startAt to query params if it exists
    if (startAt) {
      queryParams.push(`startAt=${encodeURI(JSON.stringify(startAt))}`);
    }

    // Add filter to query params if it exists
    if (filter) {
      for (const key of Object.keys(filter) as (keyof Filter)[]) {
        // only add to queryParams if the filter value exists
        if (filter[key]) {
          queryParams.push(`${key}=${encodeURI(JSON.stringify(filter[key]!))}`);
        }
      }
    }

    let url = `${environment.API_ROOT}${REFERRER_PATH}/${path}`;

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return this.http.get<IApplications[]>(url);
  }

  getApplicationsFilters() {
    return this.http.get<IApplications>(
      `${environment.API_ROOT}${REFERRER_PATH}/applicationFilters`
    );
  }

  linkCandidateToReferrals(candId: string, referrals: ReferralProp[]) {
    const resObs = forkJoin(
      referrals.map((referral) => {
        return this.http.patch(
          `${environment.API_ROOT}${REFERRER_PATH}/${referral.referralCode}/update`,
          {
            orgId: referral.orgId,
            jobId: referral.jobId,
            referralId: referral.referralCode,
            applicantUserId: candId,
          }
        );
      })
    ).pipe(share());

    resObs.subscribe(() => {
      referrals.forEach((referral) =>
        this.referralLinkService.removeReferralLink(referral.jobId)
      );
    });

    return resObs;
  }
}
