import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'apps/web/src/environments/environment';
import { PAYMENTS_SERVICE_PATH } from '../constants';
import { IPayment, IPaymentLedgerRow, PaymentStatus } from '@jr/types';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private httpClient: HttpClient) {}

  getPaymentsByStatus(status: PaymentStatus) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/all-payments/${status}`;
    return this.httpClient.get<IPayment[]>(url);
  }

  getPaymentLedgerRows() {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/payment-ledger`;
    return this.httpClient.get<IPaymentLedgerRow[]>(url);
  }

  updatePaymentStatus(
    paymentId: string,
    status: PaymentStatus,
    reason?: string
  ) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/update-status`;
    return this.httpClient.patch<IPayment>(url, {
      paymentId,
      status,
      reason,
    });
  }

  bulkUpdatePaymentStatus(
    paymentIds: string[],
    status: PaymentStatus,
    reason?: string
  ) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/bulk-update-status`;
    return this.httpClient.patch<IPayment[]>(url, {
      paymentIds,
      status,
      reason,
    });
  }
}
