import { Inject, Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { addMonths } from 'date-fns';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JRCookieService {
  constructor(
    private cookieService: SsrCookieService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // To create or update cookie
  createOrUpdate(name: string, value: string) {
    const DOMAIN = this.document.domain;

    const doesCookieExists = this.cookieService.check(name);
    // To match the domain with fastify cookie
    let domainVal = DOMAIN;
    if (DOMAIN !== 'localhost') {
      domainVal = `.${DOMAIN}`;
    }

    if (doesCookieExists) {
      this.cookieService.delete(name, '/', domainVal);
    }

    this.cookieService.set(
      name,
      value,
      addMonths(new Date(), 6),
      '/',
      domainVal
    );
  }

  // To get a cookie by name
  getCookie(name: string) {
    const cookieVal = this.cookieService.get(name);
    return cookieVal;
  }

  check(name: string) {
    return this.cookieService.check(name);
  }

  // To get all cookies
  getAllCookies() {
    return this.cookieService.getAll();
  }

  // To clear all cookies
  clearAllCookies() {
    const cookies = this.getAllCookies();
    for (const cookie in cookies) {
      this.createOrUpdate(cookie, '');
    }
  }
}
