import { Component, HostListener, Inject, OnInit } from '@angular/core';

import { BehaviorSubject, catchError, firstValueFrom, of } from 'rxjs';

import { UiModule } from '@jr/ui';
import {
  IBonus,
  IIntegration,
  IIntegrationAdmin,
  Integration,
  IntegrationConnectionStates,
  IOrganization,
  IStage,
} from '@jr/types';
import {
  BusinessProfileService,
  DialogService,
} from '../../../shared/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

type ModalData = {
  orgId: string;
  integrationType: string;
  state: IntegrationConnectionStates;
  manageMode: boolean;
};

@Component({
  standalone: true,
  imports: [UiModule],
  template: `
    <jrui-ats-integration
      [state]="
        (state$ | async) || integrationConnectionStates.AccountInformation
      "
      [integrationType]="integrationType"
      [stages]="stages"
      [adminUsers]="adminUsers"
      [enableAutoJobPost]="(enableAutoJobPost$ | async) || false"
      [bonus]="bonus"
      [manageMode]="manageMode"
      [stagesToManage]="stagesToManage"
      (update)="onUpdate($event)"
      (syncJobs)="syncJobs()"
      (jobsRedirect)="jobsRedirect()"
      (delete)="onDelete()"
      (exit)="onClose()"
    >
    </jrui-ats-integration>
  `,
})
export default class ATSIntegrationModalComponent implements OnInit {
  orgId = '';
  integrationType = '';
  isDesktop$ = new BehaviorSubject(false);
  integrationConnectionStates = IntegrationConnectionStates;
  stages!: IStage[];
  bonus!: IBonus;
  manageMode = this.data.manageMode;
  stagesToManage = new Map<string, string>();
  identifier = '';
  apiKey = '';
  adminId = '';
  adminUsers!: IIntegrationAdmin[];
  enableAutoJobPost$ = new BehaviorSubject(false);
  state$ = of(this.data.state);

  constructor(
    public dialogRef: MatDialogRef<ATSIntegrationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private businessProfileService: BusinessProfileService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.resize(window.innerWidth);
    this.integrationType = this.data.integrationType;
    this.orgId = this.data.orgId;
    await this.setExistingInfo();
    if (this.manageMode) this.manage();
    else this.checkForState();
  }

  private async checkForState() {
    // If an integration uses OAuth, skip AccountInformation state
    if (this.integrationType !== Integration.Lever) {
      this.state$ = of(IntegrationConnectionStates.AccountInformation);
      return;
    }
    const org = await firstValueFrom(
      this.businessProfileService.getBusinessProfile(this.orgId)
    );

    const integration = org.oAuth;

    if (!integration?.[this.integrationType]) {
      console.warn(`Could not connect to ${this.integrationType}.`);
      return;
    }

    this.state$ = of(IntegrationConnectionStates.SelectUser);
    return this.setExistingInfo(org);
  }

  onUpdate(data: Partial<IIntegration>) {
    const integrationObj = {
      integrationType: this.integrationType as Integration,
      identifier: data.identifier ? data.identifier : this.identifier,
      apiKey: data.apiKey ? data.apiKey : this.apiKey,
      adminId: data.adminId ? data.adminId : this.adminId,
      stages: data.stages ?? undefined,
      integrationStatus: data.integrationStatus ?? undefined,
      enableAutoJobPost: data.enableAutoJobPost ?? undefined,
    } as IIntegration;

    this.businessProfileService
      .createOrUpdateIntegration(this.orgId, integrationObj)
      .pipe(
        catchError(() => {
          this.dialogService.showAlert(
            `Could not connect to ${
              this.integrationType.charAt(0).toUpperCase() +
              this.integrationType.slice(1)
            }`,
            `The connection to ${
              this.integrationType.charAt(0).toUpperCase() +
              this.integrationType.slice(1)
            } was cancelled or could not be completed.`
          );
          this.dialogRef.close();
          return this.businessProfileService.deleteIntegration(
            this.orgId,
            this.integrationType as Integration
          );
        })
      )
      .subscribe({
        next: async (org) => {
          await this.setExistingInfo(org);
        },
      });
  }

  private async setExistingInfo(org?: IOrganization) {
    if (!org) {
      org = await firstValueFrom(
        this.businessProfileService.getBusinessProfile(this.orgId)
      );
    }
    if (this.integrationType !== Integration.Lever) {
      const ind = org.applicationTracker?.findIndex(
        (integration) =>
          integration.integrationType === (this.integrationType as Integration)
      );
      if ((ind !== 0 && !ind) || ind === -1) {
        console.warn('Could not connect to integration.');
        return;
      }
      this.identifier = org.applicationTracker?.[ind].identifier ?? '';
      this.apiKey = org.applicationTracker?.[ind].apiKey ?? '';
      this.adminId = org.applicationTracker?.[ind].adminId ?? '';
      this.adminUsers = org.applicationTracker?.[ind].adminUsers ?? [];
      this.stages = org.applicationTracker?.[ind].stages ?? [];
      org.applicationTracker?.[ind].enableAutoJobPost
        ? this.enableAutoJobPost$.next(true)
        : this.enableAutoJobPost$.next(false);
      this.bonus = org.bonus ?? {
        interviewBonus: 0,
        hireBonus: 0,
        termBonus: 0,
        termBonusPeriod: '',
      };

      return;
    }

    const integration = org.oAuth;
    this.adminId = integration?.[this.integrationType].adminId ?? '';
    this.adminUsers = integration?.[this.integrationType].adminUsers ?? [];
    this.stages = integration?.[this.integrationType].stages ?? [];
    integration?.[this.integrationType].enableAutoJobPost
      ? this.enableAutoJobPost$.next(true)
      : this.enableAutoJobPost$.next(false);
    this.bonus = org.bonus ?? {
      interviewBonus: 0,
      hireBonus: 0,
      termBonus: 0,
      termBonusPeriod: '',
    };
    return;
  }

  private manage() {
    if (this.stages) {
      for (const stage of this.stages) {
        if (stage.jrStatus) {
          this.stagesToManage.set(stage.jrStatus, stage.extStatus);
        }
      }
    }
  }

  onDelete() {
    this.businessProfileService
      .deleteIntegration(this.orgId, this.integrationType as Integration)
      .subscribe({
        error: (err) => {
          console.log('Error:', err);
        },
      });
  }

  syncJobs() {
    this.businessProfileService.syncJobs(this.integrationType).subscribe();
  }

  onClose() {
    this.dialogRef.close();
    this.router.navigate(['secure/business-profile/integrations']);
  }

  jobsRedirect() {
    this.dialogRef.close();
    this.router.navigate(['secure/jobs'], { queryParams: { pending: true } });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.resize((event.target as Window).innerWidth);
  }

  resize(width: number) {
    if (width >= 1024) {
      this.isDesktop$.next(true);
      this.dialogRef.updateSize('450px', 'auto');
      this.dialogRef.updatePosition({
        left: `${(width - 450) / 2}px`,
        top: '8vh',
      });
    } else {
      this.isDesktop$.next(false);
      this.dialogRef.updateSize(`${Math.min(width, 450)}px`, 'auto');
      this.dialogRef.updatePosition({
        left: `${width - 450 > 0 ? (width - 450) / 2 : 0}px`,
        top: '70px',
      });
    }
  }
}
