import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { REPORTS_SERVICE_PATH } from '../constants/url.constants';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  getReportData(reportId: string) {
    return this.http.get(
      `${environment.API_ROOT}${REPORTS_SERVICE_PATH}/${reportId}`
    );
  }

  resetReportData(reportId: string) {
    return this.http.get(
      `${environment.API_ROOT}${REPORTS_SERVICE_PATH}/init/${reportId}`
    );
  }
}
