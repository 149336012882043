import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    // @TODO Revisit due to Fragile of the Route Structure changing
    const signup =
      route.firstChild?.children[0].routeConfig?.path?.includes('signup') ||
      false;
    return combineLatest(
      [
        this.authService.isAuthenticated(),
        this.authService.isUserActive(),
        of(signup),
      ],
      (authenticated, active, signup) =>
        authenticated
          ? active || signup
          : this.router.createUrlTree(['/signin'])
    );
  }

  canActivateChild() {
    return this.authService.isAuthenticated();
  }
}
