/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpEventType,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  forkJoin,
  map,
  Observable,
  of,
  share,
  switchMap,
} from 'rxjs';

import { DOCUMENT_SERVICE_PATH } from '../constants/url.constants';
import { environment } from '../../../environments/environment';
import { DocumentData, DocumentLink, UploadProgress } from '@jr/types';

export type DocResponse = {
  url: string;
  fileDetails: DocumentData;
};

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  getUploadEndpoint(fileToUpload: File) {
    return this.http.post<DocResponse>(
      `${environment.API_ROOT}${DOCUMENT_SERVICE_PATH}/upload`,
      {
        originalFileName: fileToUpload.name,
        fileExtension: fileToUpload.type,
      }
    );
  }

  uploadToS3(fileToUpload: File, endpoint: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': fileToUpload.type }),
    };
    return this.http.put(endpoint, fileToUpload, httpOptions);
  }

  uploadToS3WithProgress(
    fileToUpload: File,
    endpoint: string
  ): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ 'Content-Type': fileToUpload.type });

    return this.http.request('PUT', endpoint, {
      body: fileToUpload,
      headers: headers,
      reportProgress: true,
      observe: 'events',
      responseType: 'json',
    });
  }

  upload(fileToUpload: File) {
    const endpointObs = this.getUploadEndpoint(fileToUpload).pipe(share());
    endpointObs.subscribe((res) => {
      const { url } = res;
      this.uploadToS3(fileToUpload, url).subscribe();
    });
    return endpointObs;
  }

  uploadWithProgress(fileToUpload: File): Observable<UploadProgress> {
    return this.getUploadEndpoint(fileToUpload).pipe(
      switchMap((res) => {
        const { url } = res;
        return this.uploadToS3WithProgress(fileToUpload, url).pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                // Return progress information
                return {
                  status: 'pending',
                  percentage: event.total
                    ? Math.round((100 * event.loaded) / event.total)
                    : 0,
                  bytesRemaining: event.loaded
                    ? fileToUpload.size - event.loaded
                    : fileToUpload.size,
                };

              case HttpEventType.Response:
                // Return complete response
                return {
                  status: 'complete',
                  percentage: 100,
                  bytesRemaining: 0,
                  fileDetails: res.fileDetails,
                };

              default:
                return { status: 'unknown' };
            }
          }),
          catchError((error) => {
            console.error('Upload error:', error);
            return EMPTY;
          })
        );
      }),
      catchError((error) => {
        console.error('Error getting upload endpoint:', error);
        return EMPTY;
      })
    );
  }

  getSignedUrl(docId: string): Observable<DocResponse> {
    return this.http.get<DocResponse>(
      `${environment.API_ROOT}${DOCUMENT_SERVICE_PATH}/signedurl/${docId}`
    );
  }

  delete(docId: string) {
    return this.http.delete<DocResponse>(
      `${environment.API_ROOT}${DOCUMENT_SERVICE_PATH}/signedurl/${docId}`
    );
  }

  getCandidateDocLinks(resume?: string, addlDocs?: string[]) {
    let resumeLinkObs, addlDocLinksObs;
    if (resume) {
      resumeLinkObs = this.getSignedUrl(resume).pipe(
        map((res) => {
          return {
            url: res.url,
            name: res.fileDetails.originalFileName,
            docId: resume, // only to satisfy strict type checking
          };
        })
      );
    }
    if (addlDocs && addlDocs.length > 0) {
      const docsObs = addlDocs.map((docId) => {
        return this.getSignedUrl(docId).pipe(
          map((res) => {
            return {
              url: res.url,
              name: res.fileDetails.originalFileName,
              docId: docId,
            };
          })
        );
      });
      addlDocLinksObs = forkJoin(docsObs);
    }

    return { resumeLinkObs, addlDocLinksObs };
  }

  getDocumentData(docId: string, embedUrl = ''): Observable<DocumentLink> {
    let result;
    if (docId) {
      result = this.getSignedUrl(docId).pipe(
        catchError((error) => {
          console.error(
            `Failed to get file data due to fetch signed url error: ${error}`
          );
          return EMPTY;
        }),
        map((res: DocResponse) => {
          return {
            url: res.url,
            name: res.fileDetails.originalFileName,
            docId,
          };
        })
      );
    }

    if (!result) {
      result = of({
        url: '',
        name: '',
        docId: '',
        embedUrl,
      });
    }

    return result;
  }
}
