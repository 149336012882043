import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { UiModule } from '@jr/ui';
import { InternalReferralStates, VerificationEmailTypes } from '@jr/types';
import {
  AuthService,
  BusinessProfileService,
  ProfileService,
  JRCookieService,
} from '@jr/shared/services';
import { isPlatformBrowser } from '@angular/common';

type ModalData = {
  userId: string;
  orgId: string;
  jobId: string;
  referralId: string;
  verify: boolean;
  token?: string;
};

@Component({
  standalone: true,
  imports: [UiModule],
  template: `
    <jrui-internal-employee-verification
      [data]="(data$ | async) || {}"
      [isDesktop]="(isDesktop$.asObservable() | async) || false"
      (email)="onEmail($event)"
      (exit)="onClose()"
      (goToApply)="goToApply()"
    >
    </jrui-internal-employee-verification>
  `,
})
export default class InternalReferralModalComponent implements OnInit {
  data$!: Observable<{
    state?: InternalReferralStates;
    workEmail?: string;
    orgName?: string;
    orgDomain?: string;
  }>;
  isDesktop$ = new BehaviorSubject(false);

  constructor(
    public dialogRef: MatDialogRef<InternalReferralModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private router: Router,
    private orgService: BusinessProfileService,
    private authService: AuthService,
    private profileService: ProfileService,
    private cookieService: JRCookieService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.resize(window.innerWidth);
      this.getData();
    }
  }

  getData() {
    const userObs = this.data.token
      ? this.profileService.verifyWorkEmail(this.data).pipe(
          map((user) => {
            return {
              state: user.orgEmailValidUntil
                ? InternalReferralStates.Verified
                : InternalReferralStates.InvalidLink,
              workEmail: user.orgEmail?.split('@')[0],
              verify: this.data.verify,
            };
          })
        )
      : this.profileService.getMyProfile().pipe(
          map((user) => {
            return {
              state: this.data.verify
                ? InternalReferralStates.GetStarted
                : InternalReferralStates.Confirmation,
              workEmail: user.orgEmail?.split('@')[0],
              verify: this.data.verify,
            };
          })
        );
    const orgObs = this.orgService
      .getBusinessPublicProfile(this.data.orgId)
      .pipe(
        map((org) => {
          return {
            orgName: org.orgName,
            orgDomain: org.profile?.emailDomain ?? '',
          };
        })
      );
    this.data$ = forkJoin([userObs, orgObs]).pipe(
      map((res) => {
        return {
          ...res[0],
          ...res[1],
        };
      })
    );
  }

  onEmail(email: string) {
    this.authService
      .sendVerificationEmail(
        {
          orgId: this.data.orgId,
          jobId: this.data.jobId,
          referralId: this.data.referralId,
          orgEmail: email,
          orgEmailValidUntil: '',
        },
        VerificationEmailTypes.InternalReferral
      )
      .subscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  goToApply() {
    this.authService.logout();
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('persons_pg');
    }
    this.cookieService.clearAllCookies();
    this.dialogRef.close();
    setTimeout(() => {
      this.router.navigate([`jobs/${this.data.orgId}/${this.data.jobId}`], {
        queryParams: { referralCode: this.data.referralId },
      });
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.resize((event.target as Window).innerWidth);
  }

  resize(width: number) {
    if (width >= 1024) {
      this.isDesktop$.next(true);
      this.dialogRef.updateSize('800px', '60%');
      this.dialogRef.updatePosition({
        left: `${(width - 800) / 2}px`,
        top: '20vh',
      });
    } else {
      this.isDesktop$.next(false);
      this.dialogRef.updateSize(
        `${Math.min(width, 450)}px`,
        'calc(100vh - 124px)'
      );
      this.dialogRef.updatePosition({
        left: `${width - 450 > 0 ? (width - 450) / 2 : 0}px`,
        top: '60px',
      });
    }
  }
}
