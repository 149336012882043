import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import {
  IDM_SERVICE_PATH,
  PUBLIC_SERVICE_PATH,
} from '../constants/url.constants';
import { ICandidateDetails, IIdentity } from '@jr/types';
import { Linkedin, Website, WebsitePwd } from '@jr/ui';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  getMyProfile() {
    return this.httpClient.get<IIdentity>(
      `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile`
    );
  }

  updateMyProfile(user: Partial<IIdentity>) {
    return this.httpClient.patch<IIdentity>(
      `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile`,
      user
    );
  }

  validateInviteCode(code: string) {
    return this.httpClient.get(
      `${environment.API_ROOT}${PUBLIC_SERVICE_PATH}/invite/${code}`
    );
  }

  verifyWorkEmail(data: {
    userId: string;
    orgId: string;
    jobId: string;
    referralId: string;
  }) {
    return this.httpClient.post<IIdentity>(
      `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile/verify-work-email`,
      data
    );
  }

  updateCandidateProfile(
    userProfile: Partial<IIdentity>,
    details: Partial<ICandidateDetails>
  ) {
    userProfile.firstName = details.firstName;
    userProfile.lastName = details.lastName;
    userProfile.email = details.email;

    if (details.linkedinUrl) {
      userProfile = this.updateProfileLink(
        userProfile,
        Linkedin,
        details.linkedinUrl
      );
    }
    if (details.websiteUrl) {
      userProfile = this.updateProfileLink(
        userProfile,
        Website,
        details.websiteUrl
      );
      // Dont bother saving password if website url is not provided
      if (details.websitePwd) {
        userProfile = this.updateProfileLink(
          userProfile,
          WebsitePwd,
          details.websitePwd
        );
      } else {
        userProfile = this.deleteProfileLink(userProfile, WebsitePwd);
      }
    } else {
      userProfile = this.deleteProfileLink(userProfile, Website);
      userProfile = this.deleteProfileLink(userProfile, WebsitePwd);
    }

    return this.updateMyProfile(userProfile);
  }

  private updateProfileLink(
    userProfile: Partial<IIdentity>,
    type: string,
    value: string
  ) {
    if (!userProfile?.profile_links) {
      userProfile.profile_links = [];
    }
    const index = userProfile.profile_links.findIndex(
      (link) => link.type == type
    );
    if (index === -1) {
      userProfile.profile_links.push({
        type: type,
        url: value,
      });
    } else {
      userProfile.profile_links[index].url = value;
    }

    return userProfile;
  }

  private deleteProfileLink(userProfile: Partial<IIdentity>, type: string) {
    if (!userProfile?.profile_links) {
      return userProfile;
    }
    const index = userProfile.profile_links.findIndex(
      (link) => link.type == type
    );
    if (index > -1) {
      userProfile.profile_links.splice(index, 1);
    }

    return userProfile;
  }
}
