import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BusinessService } from '@jr/shared/services';
import { INewsletter } from '@jr/types';

import { UiModule } from '@jr/ui';
import { isPlatformBrowser } from '@angular/common';

@Component({
  standalone: true,
  imports: [UiModule],
  template: `
    <jrui-newsletter-signup
      (subscribe)="submitEmail($event)"
      (exit)="onClose()"
    >
    </jrui-newsletter-signup>
  `,
})
export default class NewsletterSignupModalComponent implements OnInit {
  isDesktop$ = new BehaviorSubject(false);

  constructor(
    private businessService: BusinessService,
    public dialogRef: MatDialogRef<NewsletterSignupModalComponent>,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.resize(window.innerWidth);
    }
  }

  private setModalClosedFlag() {
    localStorage.setItem('modalClosed', 'true');
  }

  submitEmail(email: string) {
    console.log('this is email', email);
    this.subscribeToNewsletter(email);
    this.setModalClosedFlag();
    this.dialogRef.close();
  }

  onClose() {
    this.setModalClosedFlag();
    this.dialogRef.close();
  }

  async subscribeToNewsletter(email: string) {
    this.businessService
      .subscribeToNewsletter(email)
      .subscribe((newsletter: INewsletter) => {
        return newsletter;
      });
  }

  resize(width: number) {
    if (width >= 1024) {
      this.isDesktop$.next(true);
      this.dialogRef.updateSize('1162px', 'auto');
      this.dialogRef.updatePosition({
        left: `${(width - 1102) / 2}px`,
        top: '20vh',
      });
    } else {
      this.isDesktop$.next(false);
      this.dialogRef.updatePosition({
        left: `${width - 450 > 0 ? (width - 450) / 2 : 0}px`,
        top: '60px',
      });
    }
  }
}
