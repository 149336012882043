import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

import { META_TITLE } from '@jr/types';
import { environment } from 'apps/web/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  public appName = META_TITLE;
  public pageTitle = '';
  public TitleUpdated = new BehaviorSubject<string>(this.appName);

  constructor(private platformTitleService: Title, private metaService: Meta) {}

  getTitle() {
    return this.pageTitle
      ? `${this.pageTitle} - ${this.appName}`
      : this.appName;
  }

  setTitle(title: string) {
    if (title) {
      this.pageTitle = title;
      this.TitleUpdated.next(this.getTitle());
      this.platformTitleService.setTitle(this.getTitle());
    }
    this.updateMetaImages();
  }

  setRootTitle() {
    this.platformTitleService.setTitle(this.appName);
  }

  private updateMetaImages() {
    const imageCount = environment.OG_IMAGE_COUNT;
    const num = Math.floor(Math.random() * imageCount) + 1;

    const imageUrl = `https://jobring.ai/assets/og-images/jobringog-${num}.png`;

    this.metaService.updateTag({
      name: 'og:image',
      content: imageUrl,
    });
    this.metaService.updateTag({
      name: 'image',
      property: 'og:image',
      content: imageUrl,
    });
    this.metaService.updateTag({
      name: 'twitter:image',
      content: imageUrl,
    });
  }
}
