import { Injectable } from '@angular/core';
import { IIdentity, IInviteBusinessUser } from '@jr/types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private userData$ = new BehaviorSubject<Partial<IIdentity>>({});
  private invitedBusinessUserData$ = new BehaviorSubject<
    Partial<IInviteBusinessUser>
  >({});
  private editMode = new BehaviorSubject<boolean>(false);

  currentUser = this.userData$.asObservable();
  currentInvitedBusinessUser = this.invitedBusinessUserData$.asObservable();
  editModeForInvitedUser = this.editMode.asObservable();

  constructor() {}

  setUser(user: Partial<IIdentity>) {
    this.userData$.next(user);
  }

  setInvitedUser(user: Partial<IInviteBusinessUser>) {
    this.invitedBusinessUserData$.next(user);
  }

  setEditModeForInvitedUser(val: boolean) {
    this.editMode.next(val);
  }
}
