import { Component, Inject } from '@angular/core';
import { UiModule } from '@jr/ui';
import { IOrganization } from '@jr/types';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [UiModule],
  template: `
    <jrui-application-sent [data]="data" (navigateJobs)="jobsRedirect()">
    </jrui-application-sent>
  `,
})
export default class ApplicationSentComponent {
  constructor(
    public dialogRef: MatDialogRef<ApplicationSentComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: IOrganization
  ) {}

  jobsRedirect() {
    this.dialogRef.close();
    this.router.navigate(['/jobs']);
  }
}
