import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  IApplicant,
  IApplicationDetails,
  ICandidateDetails,
  IExtendJob,
  IJob,
  IOpportunityDetail,
  IReferralAttribution,
  Integration,
  JobStatus,
} from '@jr/types';
import { environment } from '../../../environments/environment';
import { JOBS_SERVICE_PATH } from '../constants/url.constants';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private http: HttpClient, private docService: DocumentService) {}

  getActiveJobs() {
    return this.http.get<IExtendJob[]>(
      `${environment.API_ROOT}${JOBS_SERVICE_PATH}/active`
    );
  }

  // Get Job by OrgId and JobId
  getActiveJob(orgId: string, jobId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}`;
    return this.http.get<IExtendJob>(url);
  }

  // Update job by OrgId and JobId
  updateJob(orgId: string, jobId: string, job: Partial<IJob>) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}`;
    return this.http.patch(url, job);
  }

  getActiveJobsByOrg(orgId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs`;
    return this.http.get<IExtendJob[]>(url);
  }

  getPendingJobs(orgId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/pending-jobs`;
    return this.http.get<IExtendJob[]>(url);
  }

  getInactiveJobs(orgId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/inactive-jobs`;
    return this.http.get<IExtendJob[]>(url);
  }

  changeAllJobsStatus(status: JobStatus, integration?: Integration) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/change-jobs-status`;
    return this.http.patch(url, { status, integration });
  }

  uploadResumeToAffinda(docId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/upload-resume-to-affinda`;
    return this.http.post<string>(url, {
      fileId: docId,
    });
  }

  getResumeImprovements(resumeId: string, jobId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/get-resume-improvements`;
    return this.http.post<string>(url, { resumeId, jobId });
  }

  applyJob(orgId: string, jobId: string, details: Partial<ICandidateDetails>) {
    // See what all files to be uploaded
    const uploadObs = {} as {
      resume?: Observable<string>;
      addlDocs?: Observable<string[]>;
    };
    // Upload resume
    if (details.resume) {
      uploadObs.resume = this.docService.upload(details.resume).pipe(
        map((res) => {
          return res.fileDetails.docId;
        })
      );
    } else {
      uploadObs.resume = of('');
    }

    // additional documents updates
    if (details.addlDocs?.length) {
      uploadObs.addlDocs = forkJoin(
        details.addlDocs.map((doc) =>
          this.docService.upload(doc).pipe(
            map((res) => {
              return res.fileDetails.docId;
            })
          )
        )
      );
    } else {
      uploadObs.addlDocs = of([]);
    }

    return forkJoin([uploadObs.resume, uploadObs.addlDocs]).pipe(
      switchMap((res) => {
        const application: Partial<IApplicant> = {
          resume: res[0],
          addlDocs: res[1],
          affindaResumeId: details.affindaResumeId,
          questions: details.questions,
        };
        return this.createApplication(orgId, jobId, application);
      })
    );
  }

  createApplication(
    orgId: string,
    jobId: string,
    application: Partial<IApplicant>
  ) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}/applicants`;
    return this.http.post<IApplicant>(url, application);
  }

  getOpportunityDetails(orgId: string, jobId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}/applicants`;
    return this.http.get<IOpportunityDetail>(url);
  }

  getApplicationDetails(orgId: string, jobId: string, applicantId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}/applicants/${applicantId}`;
    return this.http.get<IApplicationDetails>(url);
  }

  getApplicationByUserId(orgId: string, jobId: string, applicantId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}/user/${applicantId}`;
    return this.http.get<IApplicant>(url);
  }

  createReferralAttribution(data: Partial<IReferralAttribution>) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/create-referral-attribution`;
    return this.http.post<IReferralAttribution>(url, data);
  }

  getReferralAttributionUser(jobId: string, userId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/referral-attribution/job/${jobId}/user/${userId}`;
    return this.http.get<IReferralAttribution>(url);
  }

  getReferralAttributionReferrer(jobId: string, referrerId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/referral-attribution/job/${jobId}/referrer/${referrerId}`;
    return this.http.get<IReferralAttribution>(url);
  }

  getReferralAttributionJob(jobId: string) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/referral-attribution/job/${jobId}`;
    return this.http.get<IReferralAttribution>(url);
  }

  createJob(orgId: string, job: Partial<IJob>) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/create-job`;
    return this.http.post<IJob>(url, job);
  }
}
