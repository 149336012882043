import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  BusinessDashboardStatistics,
  ListingUpdate,
  ListingApplication,
  IJob,
  INewsletter,
} from '@jr/types';

import { environment } from '../../../environments/environment';
import { JOBS_SERVICE_PATH } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  constructor(private http: HttpClient) {}

  getDashboardStatistics() {
    return this.http.get<BusinessDashboardStatistics>(
      `${environment.API_ROOT}${JOBS_SERVICE_PATH}/business/dashboard-statistics`
    );
  }

  getListingUpdates() {
    return this.http.get<ListingUpdate[]>(
      `${environment.API_ROOT}${JOBS_SERVICE_PATH}/listing/updates`
    );
  }

  subscribeToNewsletter(email: string) {
    const requestBody = { email: email };
    return this.http.post<INewsletter>(
      `${environment.API_ROOT}${JOBS_SERVICE_PATH}/newsletter/subscribe`,
      requestBody
    );
  }

  updateApplicationStatus(
    orgId: string,
    jobId: string,
    applicantId: string,
    status: string,
    startDate?: string
  ) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/${jobId}/update/${applicantId}`;
    return this.http.post(url, {
      applicationStatus: status,
      startDate,
    });
  }

  getListingApplications() {
    return this.http.get<ListingApplication[]>(
      `${environment.API_ROOT}${JOBS_SERVICE_PATH}/listing/applications`
    );
  }

  updateJobListing(orgId: string, jobId: string, job: Partial<IJob>) {
    const url = `${environment.API_ROOT}${JOBS_SERVICE_PATH}/org/${orgId}/jobs/${jobId}`;
    return this.http.patch(url, job);
  }
}
