import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICustomerSupport } from '@jr/types';

import { environment } from '../../../environments/environment';
import { PUBLIC_SERVICE_PATH } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class CustomerSupportService {
  constructor(private http: HttpClient) {}

  createCustomerSupportRequest(data: Partial<ICustomerSupport>) {
    const url = `${environment.API_ROOT}${PUBLIC_SERVICE_PATH}/customer-support/request`;
    return this.http.post(url, data);
  }
}
