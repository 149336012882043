import { Component, HostListener, Inject, OnInit } from '@angular/core';

import { BehaviorSubject, catchError, EMPTY } from 'rxjs';

import { UiModule } from '@jr/ui';
import { IIdentity, VerificationEmailTypes } from '@jr/types';
import { DialogService, AuthService } from '../../../shared/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type ModalData = {
  users: IIdentity[];
};

@Component({
  standalone: true,
  imports: [UiModule],
  template: `<jrui-merge-user-profiles
    [userList]="userList$ || []"
    [isMobile]="isMobile"
    (mergeUser)="mergeUser($event)"
    (modalClose)="onClose()"
    (dismiss)="onDismiss($event)"
  >
  </jrui-merge-user-profiles>`,
})
export default class MergeUserProfilesModalComponent implements OnInit {
  isDesktop$ = new BehaviorSubject(false);
  userList$!: IIdentity[];

  constructor(
    public dialogRef: MatDialogRef<MergeUserProfilesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.resize(window.innerWidth);
    this.userList$ = this.data.users;
  }

  mergeUser(user: IIdentity) {
    const emailType = VerificationEmailTypes.AccountMerge;

    this.authService
      .sendAccountMergeVerificationEmail(user, emailType)
      .pipe(
        catchError((error) => {
          console.error(error);
          this.dialogService.showAlert(
            'Failed to send Account Merge Verification Email',
            error
          );
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.dialogService.showAlert(
          'Email verification sent. Check your inbox.',
          `A verification email was successfully sent to <strong class="jimmyPurple"> ${user.email} </strong>. Please check your inbox to finish merging your accounts.`,
          undefined,
          false
        );
      });
  }

  onClose() {
    this.dialogRef.close();
  }

  onDismiss(user: IIdentity) {
    const dismissedUserId = user.userId;
    // TODO:: Update userList to exclude this user
    console.log('dismissedUserId :: ', dismissedUserId);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.resize((event.target as Window).innerWidth);
  }

  resize(width: number) {
    if (width >= 1024) {
      this.isDesktop$.next(true);
      this.dialogRef.addPanelClass('merge-users-container');
      this.dialogRef.updateSize('450px', 'auto');
      this.dialogRef.updatePosition({
        left: `${(width - 450) / 2}px`,
        top: '8vh',
      });
    } else {
      this.isDesktop$.next(false);
      this.dialogRef.removePanelClass('merge-users-container');
      this.dialogRef.updateSize('100vw', '100vh');
    }
  }

  get isMobile(): boolean {
    return window.innerWidth < 1024;
  }
}
