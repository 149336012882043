import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';

import { SIGNUP_STEP_TO_STATUS_MAP } from '../constants/url.constants';
import { AuthService, FeatureFlagService } from '../services';
import { Features } from '@jr/types';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private featureService: FeatureFlagService
  ) {}

  async canActivateChild(route: ActivatedRouteSnapshot) {
    const userLoginV2 = (
      await firstValueFrom(
        this.featureService.getFeatureFlag(Features.userLoginV2)
      )
    )?.enabled;
    return this.authService.getLoggedInUser().pipe(
      map((user) => {
        const signupStep = route.routeConfig?.path;
        if (userLoginV2) {
          return false;
        } else if (
          !userLoginV2 &&
          signupStep &&
          user?.status &&
          SIGNUP_STEP_TO_STATUS_MAP.get(signupStep)?.includes(user.status)
        ) {
          return true;
        }
        //User not meant to be on this page.
        this.authService.logout();
        return false;
      })
    );
  }
}
