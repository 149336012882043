import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { AuthRepository } from '../../state';
import { USER_STATUS_URLS, userType } from '../constants/url.constants';
import {
  USER_COOKIE_NAME,
  PROFILE_TYPE_COOKIE,
  AUTHENTICATED_USER,
} from '@jr/types';
import { AuthService, 
  JRCookieService 
} 
from '../services';
import { UserStatus, ProfileTypes } from '@jr/ui';
import { isPlatformBrowser } from '@angular/common';

type QueryParameters = {
  t: string;
};

@Injectable({
  providedIn: 'root',
})
export class TokenGuard {
  constructor(
    private router: Router,
    private authRepo: AuthRepository,
    private cookieService: JRCookieService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const token = (route.queryParams as QueryParameters).t;
    return this.authRepo.user$.pipe(
      map((user) => {
        if (token && user?.token !== token) {
          const userInfo: userType = jwt_decode(token);
          delete userInfo.baseToken;
          this.authRepo.updateUser({ token, ...userInfo });

          const profileType = userInfo?.profileType || AUTHENTICATED_USER;

          // TODO:: Need to merge all the events for Anonymous user when this user logged in to job ring with the user's userID
          // Get a cookie for user
          const cookieUserId = this.cookieService.getCookie(USER_COOKIE_NAME);

          // Get a cookie for profile type
          const cookieProfileType =
            this.cookieService.getCookie(PROFILE_TYPE_COOKIE);

          // // If there is no cookie for profileType create a new cookie
          if (
            !cookieProfileType ||
            cookieProfileType !== userInfo.profileType
          ) {
            this.cookieService.createOrUpdate(PROFILE_TYPE_COOKIE, profileType);
          }

          const data = {
            userType: profileType,
            userId: userInfo.userId,
            tempUserId: cookieUserId,
          };
          this.authService.mergeEventsAndAttributions(data).subscribe();

          // If there is no cookie for user create a new cookie
          if (!cookieUserId || cookieUserId !== userInfo.userId) {
            this.cookieService.createOrUpdate(
              USER_COOKIE_NAME,
              userInfo.userId
            );
          }

          if (isPlatformBrowser(this.platformId)) {
            const personPg = localStorage.getItem('persons_pg');
            const routeTo =
              personPg && userInfo.status === UserStatus.Active
                ? personPg
                : USER_STATUS_URLS.get(userInfo.status) || '';
  
            // Navigate user to different pages based on their signup status
            if (
              profileType === ProfileTypes.Business &&
              userInfo.status === UserStatus.Active
            ) {
              this.router.navigate(['secure/dashboard']);
            } else {
              this.router.navigateByUrl(routeTo);
            } 
          }

          return false;
        }
        return true;
      })
    );
  }
}
