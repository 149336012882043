import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AdministratorGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    return this.authService.getLoggedInUser().pipe(
      map((user) => {
        if (
          user &&
          user.userRoles &&
          user.userRoles.includes('Administrator')
        ) {
          return true;
        }
        this.router.navigate(['/']);
        return false;
      })
    );
  }
}
