import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from '@jr/ui';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showBasicComponent(
    heading: string,
    message: string,
    panelClass: string,
    actionLabel?: string,
    onAction?: () => void
  ) {
    console.log(heading, message, actionLabel, onAction);
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { heading, message, actionLabel, onAction },
      duration: 30000,
      panelClass: [panelClass],
    });
  }
}
