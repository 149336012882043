import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  IDM_SERVICE_PATH,
  PUBLIC_SERVICE_PATH,
  JOB_PORTALS_PATH,
} from '../constants/url.constants';
import {
  IIntegration,
  IInviteBusinessUser,
  Integration,
  IOrganization,
  IPilotBusiness,
} from '@jr/types';

@Injectable({
  providedIn: 'root',
})
export class BusinessProfileService {
  constructor(private http: HttpClient) {}

  createOrUpdateBusinessProfile(orgId: string, data: Partial<IOrganization>) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}`;
    return this.http.patch<IOrganization>(url, data);
  }

  getBusinessPublicProfile(orgId: string) {
    const url = `${environment.API_ROOT}${PUBLIC_SERVICE_PATH}/org/${orgId}`;
    return this.http.get<IOrganization>(url);
  }

  getBusinessProfile(orgId: string) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}`;
    return this.http.get<IOrganization>(url);
  }

  createPilotSignupProfile(userId: string, req: Partial<IPilotBusiness>) {
    const data = {
      userId: userId,
      ...req,
    };
    const url = `${environment.API_ROOT}${PUBLIC_SERVICE_PATH}/my/profile/pilot-business-signup`;
    return this.http.post(url, data);
  }

  syncJobs(source: string) {
    const url = `${environment.API_ROOT}${JOB_PORTALS_PATH}/sync/${source}`;
    return this.http.get<{ header: string; message: string }>(url);
  }

  inviteBusinessUser(data: Partial<IInviteBusinessUser>) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/invite/business-user`;
    return this.http.post(url, data);
  }

  getAllInvitedBusinessUsers(orgId: string, userId: string) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}/user/${userId}/business-users`;
    return this.http.get<IInviteBusinessUser[]>(url);
  }

  updateInvitedBusinessUser(
    inviteId: string,
    data: Partial<IInviteBusinessUser>
  ) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/business-user/invite/${inviteId}`;
    return this.http.patch(url, data);
  }

  // API for creating or updating integration (lever or workable or greenhouse)
  createOrUpdateIntegration(orgId: string, data: IIntegration) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}/integration`;
    return this.http.patch<IOrganization>(url, data);
  }

  deleteIntegration(orgId: string, integrationType: Integration) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}/integration/${integrationType}`;
    return this.http.delete<IOrganization>(url);
  }

  setIntegrationOAuthState(orgId: string, integrationType: Integration) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}/oauth-state`;
    return this.http.patch<{ org: IOrganization; authURI: string }>(
      url,
      integrationType
    );
  }

  setIntegrationOAuthTokens(
    orgId: string,
    integrationType: Integration,
    authCode: string
  ) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/${orgId}/oauth-tokens`;
    return this.http.patch<IOrganization>(url, { integrationType, authCode });
  }
}
