import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  FeedbackDialogComponent,
  ReReferDialogComponent,
  ResumeDialogComponent,
  UploadFileDialogComponent,
} from '@jr/ui';
import ApplicationSentComponent from '../../containers/private/candidate/application-sent.component';
import ATSIntegrationModalComponent from '../../containers/private/business-profile/integration-modal.component';
import InternalReferralComponent from '../../containers/private/referrer/internal-referral-modal.component';
import LoginComponent from '../../containers/public/auth/login.component';
import MergeUserProfilesModalComponent from '../../containers/private/merge-user-profiles/merge-user-profiles-modal.component';
import NewsletterSignupModalComponent from '../../containers/private/business-landing/newsletter-signup-modal.component';
import {
  AuthState,
  IIdentity,
  IOrganization,
  IntegrationConnectionStates,
  Referral,
} from '@jr/types';

export const DIALOG_WIDTH = '92%';
export const DIALOG_WIDTH_LESS = '48%';
export const DIALOG_MAX_WIDTH = '450px';
export const DIALOG_WIDE_MAX_WIDTH = '736px';
export const DIALOG_WIDE_MAX_HEIGHT = '528px';
export const DIALOG_POSITION_TOP = '64px';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  showAlert(
    heading: string,
    message: string,
    onClose?: (confirmed?: boolean) => void,
    blocking = false,
    isError = false,
    okayButton = '',
    link?: string,
    func?: () => unknown
  ) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      panelClass: 'integration-container',
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { heading, message, isError, okayButton, link, func },
      disableClose: blocking,
      height: 'auto',
      width: DIALOG_WIDTH,
      maxWidth: DIALOG_MAX_WIDTH,
      position: {
        top: DIALOG_POSITION_TOP,
      },
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showError(
    heading: string,
    message: string,
    onClose?: () => void,
    blocking = false
  ) {
    return this.showAlert(heading, message, onClose, blocking, true);
  }

  askConfirmation(
    heading: string,
    message: string,
    confirmButton = 'Confirm',
    cancelButton = 'Cancel',
    onClose?: (confirmed: boolean) => void,
    blocking = true
  ) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'integration-container',
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { heading, message, confirmButton, cancelButton },
      disableClose: blocking,
      width: DIALOG_WIDTH,
      maxWidth: DIALOG_MAX_WIDTH,
      position: {
        top: DIALOG_POSITION_TOP,
      },
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showFeedbackModal(
    message: string,
    inputLabel: string,
    confirmButton = 'Send Feedback',
    cancelButton = 'Cancel',
    onClose?: ({
      confirmed,
      feedback,
    }: {
      confirmed: boolean;
      feedback: string;
    }) => void,
    blocking = true
  ) {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      panelClass: 'integration-container',
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { message, inputLabel, confirmButton, cancelButton },
      disableClose: blocking,
      width: DIALOG_WIDTH,
      maxWidth: DIALOG_MAX_WIDTH,
      position: {
        top: DIALOG_POSITION_TOP,
      },
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showNewsletterModal(blocking = true, onClose?: () => void) {
    const dialogRef = this.dialog.open(NewsletterSignupModalComponent, {
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      disableClose: blocking,
      minHeight: '645px',
      maxHeight: '1150px',
      width: '90%',
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showInternalReferralModal(
    userId: string,
    orgId: string,
    jobId: string,
    referralId: string,
    verify: boolean,
    token?: string,
    onClose?: () => void,
    blocking = true
  ) {
    const dialogRef = this.dialog.open(InternalReferralComponent, {
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { userId, orgId, jobId, referralId, verify, token },
      disableClose: blocking,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showIntegrationModal(
    orgId: string,
    integrationType: string,
    state?: IntegrationConnectionStates,
    manageMode = false,
    onClose?: () => void,
    blocking = true
  ) {
    const dialogRef = this.dialog.open(ATSIntegrationModalComponent, {
      panelClass: 'integration-container',
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { orgId, integrationType, state, manageMode },
      disableClose: blocking,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showResumeRecommendationsModal(
    recommendations: string[],
    onClose?: () => void
  ) {
    const dialogRef = this.dialog.open(ResumeDialogComponent, {
      panelClass: 'recommendations-container',
      backdropClass: 'cdk-overlay-dark-backdrop',
      data: { recommendations },
      disableClose: false,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showReReferModal(referral: Referral) {
    const dialogRef = this.dialog.open(ReReferDialogComponent, {
      panelClass: 'integration-container',
      backdropClass: 'cdk-overlay-dark-backdrop',
      data: { referral },
      disableClose: true,
      height: '100%',
      maxHeight: DIALOG_WIDE_MAX_HEIGHT,
      width: DIALOG_WIDTH,
      maxWidth: DIALOG_WIDE_MAX_WIDTH,
      position: {
        top: DIALOG_POSITION_TOP,
      },
    });

    return dialogRef.componentInstance.resendReferral.asObservable();
  }

  showSentModal(data: IOrganization, onClose?: () => void) {
    const dialogRef = this.dialog.open(ApplicationSentComponent, {
      backdropClass: 'cdk-overlay-dark-backdrop',
      data,
      disableClose: false,
      maxWidth: DIALOG_WIDE_MAX_WIDTH,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showUploadFileModal(
    heading: string,
    fileType: string,
    dragAndDropTxt = '',
    leftBtnTxt = '',
    rightBtnTxt = '',
    isMobile = false,
    onClose?: () => void
  ): MatDialogRef<UploadFileDialogComponent> {
    const width = isMobile ? '100%' : DIALOG_WIDTH_LESS;
    const maxWidth = isMobile ? '100%' : DIALOG_WIDE_MAX_WIDTH;

    const params = {
      panelClass: 'upload-file-container',
      backdropClass: 'cdk-overlay-dark-backdrop',
      data: {
        heading,
        fileType,
        step: 'start',
        progress: 0,
        bytesRemaining: 0,
        previousSteps: [],
        dragAndDropTxt,
        leftBtnTxt,
        rightBtnTxt,
        isMobile,
      },
      height: 'auto',
      disableClose: true,
      width: width,
      maxWidth: maxWidth,
      position: {
        top: isMobile ? '0px' : DIALOG_POSITION_TOP,
      },
    };

    const dialogRef: MatDialogRef<UploadFileDialogComponent> = this.dialog.open(
      UploadFileDialogComponent,
      params
    );
    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef;
  }

  showLoginModal(
    isSignup = false,
    state = AuthState.Login,
    onClose?: () => void
  ) {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'integration-container',
      backdropClass: 'cdk-overlay-dark-backdrop',
      data: { isSignup, state },
      disableClose: true,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }

  showMergeAccountsModal(
    users: IIdentity[],
    onClose?: (confirmed?: boolean) => void,
    blocking = false
  ) {
    const dialogRef = this.dialog.open(MergeUserProfilesModalComponent, {
      panelClass: 'merge-users-container',
      backdropClass: blocking
        ? 'cdk-overlay-dark-backdrop'
        : 'cdk-overlay-transparent-backdrop',
      data: { users },
      disableClose: blocking,
    });

    if (onClose) {
      dialogRef.afterClosed().subscribe(onClose);
    }
    return dialogRef.afterClosed();
  }
}
