import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlagData } from '@jr/types';
import { environment } from 'apps/web/src/environments/environment';
import { FEATURE_FLAG_PATH } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private http: HttpClient) {}

  /**
   * Get's a single feature flag back by name
   * @param flag string
   * @returns Flag
   */
  getFeatureFlag(flag: string) {
    const url = `${environment.API_ROOT}${FEATURE_FLAG_PATH}/all/flags/${flag}`;
    return this.http.get<FlagData>(url);
  }

  /**
   * Returns all feature flags
   * @returns Flags
   */
  getAllFeatureFlags() {
    const url = `${environment.API_ROOT}${FEATURE_FLAG_PATH}/all/flags`;
    return this.http.get(url);
  }
}
