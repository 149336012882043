import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  Referral,
  ReferrerDashboardStatistics,
  IReferral,
  IReferralHistory,
  BusinessMoneyDashboardStatistics,
} from '@jr/types';
import { environment } from '../../../environments/environment';
import { REFERRER_PATH } from '../constants/url.constants';

type Filter = {
  type?: [string];
  status?: [string];
  company?: [string];
  terms?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ReferrerService {
  constructor(private http: HttpClient) {}

  getDashboardStatistics() {
    return this.http.get<ReferrerDashboardStatistics>(
      `${environment.API_ROOT}${REFERRER_PATH}/dashboard-statistics`
    );
  }

  getBusinessDashboardStatistics() {
    return this.http.get<BusinessMoneyDashboardStatistics>(
      `${environment.API_ROOT}${REFERRER_PATH}/business/dashboard-statistics`
    );
  }

  getReferrals(
    startAt?: {
      pkey: string;
      skey: string;
      apkey: string;
      askey: string;
    },
    filter?: Filter
  ) {
    // Base path
    const path = 'all';

    // Query params array
    const queryParams: string[] = [];

    // Add startAt to query params if it exists
    if (startAt) {
      queryParams.push(`startAt=${encodeURI(JSON.stringify(startAt))}`);
    }

    // Add filter to query params if it exists
    if (filter) {
      for (const key of Object.keys(filter) as (keyof Filter)[]) {
        // only add to queryParams if the filter value exists
        if (filter[key]) {
          queryParams.push(`${key}=${encodeURI(JSON.stringify(filter[key]!))}`);
        }
      }
    }

    let url = `${environment.API_ROOT}${REFERRER_PATH}/${path}`;

    if (queryParams && queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return this.http.get<Referral[]>(url);
  }

  getReferralFilters() {
    return this.http.get<IReferral>(
      `${environment.API_ROOT}${REFERRER_PATH}/referralFilters`
    );
  }

  getReferral(orgId: string, jobId: string, referralId: string) {
    return this.http.get<IReferral>(
      `${environment.API_ROOT}${REFERRER_PATH}/org/${orgId}/jobs/${jobId}/referrals/${referralId}`
    );
  }

  getReferralHistory(referralId: string) {
    return this.http.get<IReferralHistory>(
      `${environment.API_ROOT}${REFERRER_PATH}/referrals/${referralId}/history`
    );
  }

  getApplicationHistory(applicantId: string) {
    return this.http.get<IReferralHistory>(
      `${environment.API_ROOT}${REFERRER_PATH}/referrals/${applicantId}/application-history`
    );
  }

  referCandidate(userId: string, jobId: string, details: Partial<IReferral>) {
    const url = `${environment.API_ROOT}${REFERRER_PATH}/${userId}/job/${jobId}/referrals`;
    return this.http.post<IReferral>(url, details);
  }

  sendReferralEmail(orgId: string, jobId: string, referralId: string) {
    const url = `${environment.API_ROOT}${REFERRER_PATH}/${referralId}/send-referral-email`;
    return this.http.get<IReferral>(url, { params: { orgId, jobId } });
  }
}
