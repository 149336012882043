import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthRepository } from '../../state';
import { environment } from 'apps/web/src/environments/environment';
import { PAYMENTS_SERVICE_PATH, STRIPE_ACCOUNT_LINK } from '../constants';
import {
  CustomerAccount,
  StripeAccountLink,
  StripeConnectedAccount,
  StripeCustomerAccount,
} from '@jr/types';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(
    private httpClient: HttpClient,
    private authRepo: AuthRepository,
    private router: Router
  ) {}

  createConnectedAccount() {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/create-account`;
    return this.httpClient.get<StripeConnectedAccount>(url);
  }

  getConnectedAccount(acctId: string) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/accounts/${acctId}`;
    return this.httpClient.get<StripeConnectedAccount>(url);
  }

  getAccountLink(acctId: string) {
    const url = `${environment.API_ROOT}${STRIPE_ACCOUNT_LINK}/${acctId}`;
    return this.httpClient.get<StripeAccountLink>(url);
  }

  // Delete Connected Account
  deleteStripeAccount(acctId: string) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/delete-account/${acctId}`;
    return this.httpClient.delete(url);
  }

  createCustomerAccount(data: CustomerAccount) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/create-customer-account`;
    return this.httpClient.post<StripeCustomerAccount>(url, data);
  }

  // Delete Customer Account
  deleteCustomerAccount(acctId: string) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/delete-customer-account/${acctId}`;
    return this.httpClient.delete(url);
  }

  getCustomerAccount(acctId: string) {
    const url = `${environment.API_ROOT}${PAYMENTS_SERVICE_PATH}/customers/${acctId}`;
    return this.httpClient.get<StripeCustomerAccount>(url);
  }
}
