import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import { ReferralProp, ReferralRepository } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class ReferralLinkService {
  constructor(private referralRepo: ReferralRepository) {}

  getReferralLink(jobId: string) {
    return this.referralRepo.getReferral(jobId);
  }

  addReferralLink(referral: ReferralProp) {
    this.referralRepo.addReferral(referral);
  }

  removeReferralLink(jobId: string) {
    this.referralRepo.removeReferral(jobId);
  }

  getAllDirectReferrals() {
    return this.referralRepo.referralLinks$.pipe(
      map((referrals) =>
        referrals.filter((referral) => !!referral.referralCode)
      )
    );
  }
}
